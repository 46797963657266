























import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  props: ["item", "retailerId"],
  data() {
    const that = this;
    const dataId = that.item._id
    return {
      tableOptions: {
        content: {
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            date: {
              text: "Date",
              options: {
                // format: dd-mm-yyyy
                transform: (value) => new Date(value).toLocaleDateString("en-GB"),
              },
            },
            point: {
              text: "Total"
            },
            status: {
              text: "Status",
            },
            describe: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Description",
            },
            action: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Action",
            }
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["systemAdmin", "admin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
                ext: {
                  condition: (_, item) => {
                    return item.status === 0;
                  }
                }
              },
              info: {
                content: {
                  icon: "mdi-information-outline",
                },
                on: {
                  async xClick() {
                    const filters = [
                      {
                        key: "tenantId",
                        operator: "equal_to",
                        value: dataId,
                      },
                      {
                        key: "default",
                        operator: "equal_to",
                        value: true,
                      },
                    ];
                    const { items } = await coreApiClient.call(
                      "userPayments",
                      "findAll",
                      {
                        payload: JSON.stringify({
                          orderBy: "name",
                          orderType: "asc",
                          filters,
                        }),
                      }
                    );
                    if (items.length !== 0) {
                      alert(
                        "Bank Card Number: " + items[0].bankCardNumber + "\n" +
                        "Card Holder Name: " + items[0].cardHolderName + "\n" +
                        "Bank Name: " + items[0].bankName + "\n" +
                        "Bank Branch: " + items[0].bankBranch + "\n"
                      );
                    }
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Information",
                    },
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const id = that.item._id;
              const payload = JSON.parse(options.payload || "{}")
              if (!payload.filters) {
                payload.filters = [];
              }
              payload.filters = [
                ...payload.filters,
                {
                  key: "cashbackId",
                  operator: "equal_to",
                  value: id,
                },
              ];
              options.payload = JSON.stringify(payload);
              const { items, count } = await coreApiClient.call("cashbackDetails", "findAll", options);
              return [items, count];
            },
            async update(item) {
              return await coreApiClient.call(
                "cashBacks",
                "update",
                {
                  id: item._id,
                },
                {
                  data: { ...item, status: 1 },
                }
              );
            },
          },
          default: {
            defaultInputs: {
              status: {
                type: "boolean",
                attrs: {
                  label: "Paid",
                },
                ext: {
                  defaultValue: true,
                },
              },
            },
            editForm: userManager.checkRole(["systemAdmin", "admin"]) && {
              content: {
                fieldNames: ["describe"],
              },
            },
          },
        },
      },
    };
  },
  methods: {
    async submitPay() {
      const { listSubmit, description } = this;
      await coreApiClient.call(
        "cashBacks",
        "updateMany",
        {},
        {
          data: [...listSubmit.map((item) => ({ ...item, status: 1, describe: description }))],
        }
      );
      this.dialog.isActive = false;
      // reload page
      this.$router.go();
    },
    async initialize() {
      const id = this.$route.params.id;
      const filters = [
        {
          key: "tenantId",
          operator: "equal_to",
          value: id,
        },
        {
          key: "default",
          operator: "equal_to",
          value: true,
        },
      ];
      const { items } = await coreApiClient.call(
        "userPayments",
        "findAll",
        {
          payload: JSON.stringify({
            orderBy: "name",
            orderType: "asc",
            filters,
          }),
        }
      );
      if (items.length !== 0) {
        this.infoPayment = items[0];
      }
    },
  },
  created() {
    if (userManager.checkRole(["systemAdmin", "admin"])) {
      this.initialize();
    }
  },
});

