






























































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import CashbackDetailInfo from "@/views/app/CashbackDetailInfo.vue";
import BlurredTenant from '@/components/BlurredTenant.vue';

export default Vue.extend({
  components: {CashbackDetailInfo, BlurredTenant},
  data() {
    const that = this;
    const dataId = that.$route.params.id
    return {
      showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      listSubmit: [],
      infoPayment: {},
      description: "",
      sumTotal: 0,
      showDialog: false,
      dialog: {
        isActive: false,
        isLogout: true,
        title: 'Pay Cashback',
      },
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "date",
          "sort-desc": true,
          "show-expand": true,
        },
        content: {
          name: userManager.checkRole([
            "systemAdmin",
            "admin",
          ]) ? "Cashback" : " Revenue",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              status: {
                type: "select",
                attrs: {
                  label: "Status",
                  items: [
                    { text: "Paid", value: 1 },
                    { text: "Unpaid", value: 0 },
                  ],
                },
                rules: {
                  equal_to: {},
                },
              },
              point: {
                type: "number",
                attrs: {
                  label: "Total",
                },
                rules: {
                  greater_than: {
                    attrs: {
                      label: "Total (min)",
                      xOptions: {
                        content: {
                          resultFilter: () => 0,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          displayFields: {
            date: {
              text: "Date",
              options: {
                // format: dd-mm-yyyy
                transform: (value) => new Date(value).toLocaleDateString("en-GB"),
              },
            },
            point: {
              text: "Total"
            },
            status: {
              text: "Status",
            },
            describe: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Description",
            },
          },
          multiSelectActionButtons: {
            export: userManager.checkRole([
              "systemAdmin",
              "admin",
            ]) && {
              content: {
                text: "Pay",
                icon: "mdi-recurring-payment",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  const listSubmit = dataTable.model.value.filter((item) => item.status !== 1).map((item) => ({ ...item }));
                  let sum = 0;
                  dataTable.model.value.forEach((item) => {
                    if (item.status === 0) {
                      sum += item.point;
                    } else if (item.status === 2) {
                      sum += (item.point - item.partialPayment);
                    }
                  });
                  for (let i = 0; i < listSubmit.length; i++) {
                    if (listSubmit[i].status === 2) {
                      listSubmit[i].point = (listSubmit[i].point - listSubmit[i].partialPayment)
                    }
                  }
                  that.description = ""
                  that.listSubmit = listSubmit;
                  that.sumTotal = sum;
                  that.dialog.isActive = true;
                },
              },
            },
          },
          topActionButtons: {

          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["systemAdmin", "admin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
                ext: {
                  condition: (_, item) => {
                    return item.status !== 1;
                  }
                }
              },
              info: {
                content: {
                  icon: "mdi-information-outline",
                },
                on: {
                  async xClick() {
                    const filters = [
                      {
                        key: "tenantId",
                        operator: "equal_to",
                        value: dataId,
                      },
                      {
                        key: "default",
                        operator: "equal_to",
                        value: true,
                      },
                    ];
                    const { items } = await coreApiClient.call(
                      "userPayments",
                      "findAll",
                      {
                        payload: JSON.stringify({
                          orderBy: "name",
                          orderType: "asc",
                          filters,
                        }),
                      }
                    );
                    if (items.length !== 0) {
                      alert(
                        "Bank Card Number: " + items[0].bankCardNumber + "\n" +
                        "Card Holder Name: " + items[0].cardHolderName + "\n" +
                        "Bank Name: " + items[0].bankName + "\n" +
                        "Bank Branch: " + items[0].bankBranch + "\n"
                      );
                    }
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Information",
                    },
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const id = that.$route.params.id;
              return await that.getAll(id, options);
            },
          },
          default: {
            defaultInputs: {
              status: {
                type: "boolean",
                attrs: {
                  label: "Paid",
                },
                ext: {
                  defaultValue: true,
                },
              },
            },
            editForm: userManager.checkRole(["systemAdmin", "admin"]) && {
              content: {
                fieldNames: ["describe"],
              },
            },
          },
        },
      },
    };
  },
  methods: {
    async getAll(id, options) {
      const payload = JSON.parse(options.payload || "{}")
      if (!payload.filters) {
        payload.filters = [];
      }
      
      if (userManager.checkRole(["retailer"], true)) {
          payload.filters.push({
              key: "retailerUserId",
              operator: "equal_to",
              value: userManager.getUserInfo()._id,
          });
      } else {
        payload.filters = [
          ...payload.filters,
          {
            key: "tenantId",
            operator: "equal_to",
            value: id,
          },
        ];
      }
      options.payload = JSON.stringify(payload);
      const { items, count } = await coreApiClient.call("cashBacks", "findAll", options);
      return [items, count];
    },
    async submitPay() {
      const { listSubmit, description } = this;
      await coreApiClient.call(
        "cashBacks",
        "updateMany",
        {},
        {
          data: [...listSubmit.map((item) => ({ ...item, status: 1, describe: description }))],
        }
      );
      this.dialog.isActive = false;
      // reload page
      this.$router.go();
    },
    async initialize() {
      const id = this.$route.params.id;
      const filters = [
        {
          key: "tenantId",
          operator: "equal_to",
          value: id,
        },
        {
          key: "default",
          operator: "equal_to",
          value: true,
        },
      ];
      const { items } = await coreApiClient.call(
        "userPayments",
        "findAll",
        {
          payload: JSON.stringify({
            orderBy: "name",
            orderType: "asc",
            filters,
          }),
        }
      );
      if (items.length !== 0) {
        this.infoPayment = items[0];
      }
    },
  },
  created() {
    if (userManager.checkRole(["systemAdmin", "admin"])) {
      this.initialize();
    }
  },
});

